import axios from "axios";
import { USER_LOADED } from "./types";
import backend from "./backend";
import { setAlert } from "./alert";

export const handleStripeToken = (
  token
) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${backend}/api/stripe`,
      token
    );
    console.log(res);
    dispatch({
      type: USER_LOADED,
      // payload is 'user'
      payload: res.data,
    });
    dispatch(
      setAlert(
        "Payment succesfull",
        "success"
      )
    );
  } catch (error) {
    console.log(error);
  }
};
