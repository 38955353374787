import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { add5 } from "../../actions/survey";
import Payments from "../surveys/Payments";

const Navbar = ({
  auth: {
    isAuthenticated,
    loading,
    user,
  },
  add5,
  logout,
}) => {
  // useEffect(() => {
  //   add5();
  // }, [add5]);

  const authLinks = (
    <ul>
      <li>
        <Link to="/profiles">
          Developers
        </Link>
      </li>
      <li>
        <Link to="/posts">Posts</Link>
      </li>
      <li>
        <Payments />
        {/* <i className='fas fa-sign-out-alt' /> */}
        {/* <span className="hide-sm">
            Payments
          </span> */}
      </li>
      {/* <li>
        <button
          onClick={() => {
            add5();
          }}
        >
          Add 5$
        </button>
      </li> */}
      {user && (
        <li>
          <p>Credits:{user.credits}</p>
        </li>
      )}
      <li>
        <Link to="/dashboard">
          <i className="fas fa-user" />
          <span className="hide-sm">
            Dashboard
          </span>
        </Link>
      </li>

      <li>
        <a onClick={logout} href="#!">
          <i className="fas fa-sign-out-alt" />
          <span className="hide-sm">
            Logout
          </span>
        </a>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to="/profiles">
          Developers
        </Link>
      </li>
      <li>
        <Link to="/register">
          Register
        </Link>
      </li>
      <li>
        <Payments />
        {/* <i className='fas fa-sign-out-alt' /> */}
        {/* <span className="hide-sm">
            Payments
          </span> */}
      </li>

      <li>
        <Link to="/login">Login</Link>
      </li>
    </ul>
  );

  return (
    <nav className="navbar bg-dark">
      <h1>
        <Link to="/">
          <i className="fas fa-code" />{" "}
          DevConnector
        </Link>
      </h1>
      {!loading && (
        <Fragment>
          {isAuthenticated
            ? authLinks
            : guestLinks}{" "}
        </Fragment>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  // ptfr
  logout: PropTypes.func.isRequired,
  // ptor
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
});

export default connect(
  mapStateToProps,
  { logout, add5 }
)(Navbar);
