// Survey Form review
import React from "react";
import { connect } from "react-redux";
import formFields from "./formFields";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { submitSurvey } from "./../../actions/survey";

const SurveyFormReview = ({
  onCancel,
  formValues,
  submitSurvey,
  history,
}) => {
  const reviewFields = _.map(
    formFields,
    (field) => {
      return (
        <div key={field.label}>
          <h4>{field.label}</h4>
          <div>
            {formValues[field.name]}
          </div>
        </div>
      );
    }
  );

  return (
    <div>
      <h1>
        Please confirm your entries
      </h1>
      <div>{reviewFields}</div>
      <button
        className="btn btn-secondary my-1"
        onClick={onCancel}
      >
        Back / Edit
      </button>
      <button
        className="btn btn-success my-1"
        onClick={() =>
          submitSurvey(
            formValues,
            history
          )
        }
      >
        Send Survey
      </button>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    formValues:
      state.form.surveyForm.values,
  }; // redux-form
}

export default connect(
  mapStateToProps,
  { submitSurvey }
)(withRouter(SurveyFormReview));
