import React, {
  Component,
} from "react";
import StripeCheckout from "react-stripe-checkout";
import { handleStripeToken } from "../../actions/payment";
import { connect } from "react-redux";

class Payments extends Component {
  render() {
    return (
      <StripeCheckout
        amount={500} // 5$
        token={(token) =>
          // console.log(token)
          // console.log(token)
          this.props.handleStripeToken(
            token
          )
        }
        stripeKey={
          process.env
            .REACT_APP_STRIPE_PUBLISHABLE_KEY
        }
      >
        <button className="btn">
          Add credits
        </button>
      </StripeCheckout>
    );
  }
}

export default connect(null, {
  handleStripeToken,
})(Payments);
