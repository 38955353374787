import { SURVEY_SENT } from "./../actions/types";

const initialState = {
  loading: true,
  survey: "",
};

export default function (
  state = initialState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case SURVEY_SENT:
      return {
        ...state,
        survey: payload,
        loading: false,
      };

    default:
      return state;
  }
}
