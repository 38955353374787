import axios from "axios";
import {
  SURVEY_SENT,
  ADD_5,
  AUTH_ERROR,
  USER_LOADED,
} from "./types";
import backend from "./backend";
import setAuthToken from "../utils/setAuthToken";

export const submitSurvey = (
  values,
  history
) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${backend}/api/surveys`,
      values
    );
    // console.log(res);
    dispatch({
      type: SURVEY_SENT,
      // payload is 'user'
      payload: "Survey Sent",
    });
    dispatch({
      type: USER_LOADED,
      // payload is 'user'
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
  history.push("/");
};

export const add5 = () => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${backend}/api/stripe/add5`
    );
    // console.log(res.data);
    dispatch({
      type: ADD_5,
      // payload is 'user'
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
    console.log(err);
  }
};
