import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import DashboardEmaily from "../DashboardEmaily";

const Landing = ({
  auth: { isAuthenticated, loading },
  logout,
}) => {
  const loginButtons = (
    <>
      <h1 className="x-large">
        Developer Connector
      </h1>
      <p className="lead">
        Create a developer
        profile/portfolio, share posts
        and get help from other
        developers
      </p>
      <div className="buttons">
        <Link
          to="/register"
          className="btn btn-primary"
        >
          Sign Up
        </Link>
        <Link
          to="/login"
          className="btn btn-light"
        >
          Login
        </Link>
      </div>
      <DashboardEmaily />
    </>
  );

  const contentLoaded = (
    <>
      <p>You're connected, biatch!</p>
      <DashboardEmaily />
    </>
  );

  return (
    <section className="landing">
      <div className="dark-overlay">
        <div className="landing-inner">
          {!loading && (
            <Fragment>
              {isAuthenticated
                ? contentLoaded
                : loginButtons}{" "}
            </Fragment>
          )}
        </div>
      </div>
    </section>
  );
};

Landing.propTypes = {
  // ptfr
  logout: PropTypes.func.isRequired,
  // ptor
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { logout }
)(Landing);
